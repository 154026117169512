.editor {
  width: 100vw;
  height: 100vh;
  background: var(--bg-primary);
  z-index: 1;
}

.editor__content {
  height: calc(100vh - 55px);
  width: calc(100vw - 20px);
  overflow: hidden;
  /*border: 1px solid var(--border);*/
  /*border-radius: 16px;*/
  /*background: var(--bg-secondary);*/
  margin: 45px 10px 10px;
  display: flex;
  justify-content: center;
  gap: 4px;
  /*grid-template-columns: 1fr 1fr;*/
  /*overflow-y: scroll;*/
}

.editor__edit {
  background: var(--bg-secondary);
  border: 1px solid var(--border);
  border-radius: 16px 6px 6px 16px;
  height: calc(100vh - 55px);

  width: 100%;
  padding: 1.2em 14px;
  resize: none;
  font-size: inherit;
  font-family: 'jetbrains_mono';
  outline: var(--fg-primary);
  box-sizing: border-box;
  overflow-y: scroll;
}

.editor__edit_only {
  border-radius: 16px;
}

.editor__edit textarea {
  border: none;
  width: 100%;
  background: var(--bg-secondary);
  color: var(--fg-primary);
  resize: none;
}

.editor__edit textarea:focus {
  outline: 0;
}

.editor__preview {
  padding: 0 25px 25px 25px;
  font-size: 1rem;
  line-height: 2rem;
  width: 100%;
  height: calc(100vh - 55px);
  background: var(--bg-secondary);
  border: 1px solid var(--border);
  border-radius: 6px 16px 16px 6px;
  overflow-y: scroll;
}

.editor__preview_only {
  border-radius: 16px;
}

.editor__buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: 4px;
  padding: 6px 10px;
  width: 100%;
  position: fixed;
  z-index: 1;
  background-color: var(--bg-primary);
}

@media only screen and (max-width: 425px) {
  .editor {
    position: absolute;
  }

  .editor__buttons {
    padding: 5px 10px;
  }

  .editor__preview {
    padding: 0 8px 8px 8px;
    /*width: calc(50vw - 11px);*/
    overflow-wrap: break-word;
  }

  /*.editor__edit {
    width: calc(50vw - 12px);
  }*/
}
