.list-notes {
  border-width: 0 1px;
  grid-area: c;
}

.list-notes__items {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding: 0;
}
.list-notes__item {
  padding: 17px 24px;
  cursor: pointer;
  background: var(--bg-secondary);
  border: 1px solid var(--border);
  border-radius: 16px;
  margin: 8px 0;
  position: relative;
  list-style: none;
}

.list-notes__item_title {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 76%;
}

.list-notes__button {
  position: absolute;
  right: 24px;
}
.list-notes__item_body {
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-notes__item p {
  margin: 10px 0;
  max-height: 70px;
}
.list-notes__item small {
  display: block;
  font-family: 'Manrope-Light';
}

.list-notes__item:hover {
  transition: all .1s;
  border: 1.5px solid var(--fg-secondary)
}
