* {
  box-sizing: border-box;
  letter-spacing: 0.8px;
}

::-webkit-scrollbar {
  width: 0;
}

code {
  background: var(--fg-primary);
  color: var(--bg-primary);
  padding: 3px 5px;
  font-family: 'Manrope-Regular';
}

table,
td,
th {
  border: 1px solid var(--border);
  /*padding: 5px;*/
}

tbody td {
  padding: 8px;
}

table {
  /*width: 100%;*/
  border-collapse: collapse;
}

h1 {
  margin: 0.5em 0;
}

.app {
  font-family: 'Manrope-Regular';
  font-size: 15px;
  background: var(--bg-primary);
  color: var(--fg-primary);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /*display: flex;
  flex-direction: column;
  align-items: center;*/
  display: grid;
  /*justify-items: center;*/
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 70px 1px 0fr 20px;
  grid-template-areas:
    '. . . h h h h h h . . .'
    'l l l l l l l l l l l l'
    '. . . c c c c c c . . .'
    'f f f f f f f f f f f f';
}

.line {
  background: var(--border);
  grid-area: l;
}

.button {
  background: var(--bg-secondary);
  color: var(--fg-secondary);
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 7px;
  cursor: pointer;
  height: fit-content; /*фикс высоты кнопок*/
}

.button:hover {
  background: var(--fg-primary);
  color: var(--bg-primary);
}

.delete-button {
  border: 1px solid var(--border);
}

.delete-button:hover {
  background: var(--error_fg);
  color: var(--bg-primary);
  border: 1px solid var(--border);
}

@media only screen and (max-width: 820px) {
  .app {
    grid-template-rows: 70px 1px 0fr 20px;
    grid-template-areas:
      '. h h h h h h h h h h .'
      'l l l l l l l l l l l l'
      '. . c c c c c c c c . .'
      'f f f f f f f f f f f f';
  }
}

@media only screen and (max-width: 768px) {
  .app {
    grid-template-rows: 70px 1px 0fr 20px;
    grid-template-areas:
      '. h h h h h h h h h h .'
      'l l l l l l l l l l l l'
      '. c c c c c c c c c c .'
      'f f f f f f f f f f f f';
  }

  .list-notes {
    margin: 0 25px;
  }
}
/*
@media only screen and (max-width: 425px) {
  .app {
    grid-template-rows: 70px 1px 0fr 20px;
    grid-template-areas:
      '. h h h h h h h h h h .'
      'l l l l l l l l l l l l'
      '. c c c c c c c c c c .'
      'f f f f f f f f f f f f';
  }
}*/

@media only screen and (max-width: 425px) {
  .app {
    font-size: 16px;
    grid-template-rows: 70px 1px 0fr 20px;
    grid-template-areas:
      'h h h h h h h h h h h h'
      'l l l l l l l l l l l l'
      'c c c c c c c c c c c c'
      'f f f f f f f f f f f f';
  }
}
