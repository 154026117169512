.header {
grid-area: h;
}

.header__container {
  width: 100%;
  display: flex;
  padding: 0 25px;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.header__content {
  /*max-width: 800px;*/
  height: 40px;
  width: 100%;
  /*border: 1px solid;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header__logo_text {
  margin: 0;
  font-family: 'Manrope-ExtraBold';
  font-weight: 900;
  font-size: 42px;
}

.header__logo_img {
  width: 32px;
}