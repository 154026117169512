.warn-message {
  background-color: #24292e;
  color: var(--warn_fg);
  border: 3px solid var(--error_fg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  display: none;
}

.warn-message__text {
  padding: 0 15px;
  white-space: pre-wrap;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 55px;
}

@media (prefers-color-scheme: light) {
  .warn-message {
    background-color: #24292e;
  }
}

@media (prefers-color-scheme: dark) {
  .warn-message {
    background-color: var(--bg-primary);
  }
}