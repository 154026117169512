@font-face {
  font-family: 'Manrope-Bold';
  src: url('./fonts/Manrope-Bold.woff2') format('woff2'),
    url('./fonts/Manrope-Bold.woff') format('woff'),
    url('./fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-Semibold';
  src: url('./fonts/Manrope-Semibold.woff2') format('woff2'),
    url('./fonts/Manrope-Semibold.woff') format('woff'),
    url('./fonts/Manrope-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-Regular';
  src: url('./fonts/Manrope-Regular.woff2') format('woff2'),
    url('./fonts/Manrope-Regular.woff') format('woff'),
    url('./fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-Light';
  src: url('./fonts/Manrope-Light.woff2') format('woff2'),
    url('./fonts/Manrope-Light.woff') format('woff'),
    url('./fonts/Manrope-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-Thin';
  src: url('./fonts/Manrope-Thin.woff2') format('woff2'),
    url('./fonts/Manrope-Thin.woff') format('woff'),
    url('./fonts/Manrope-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-Medium';
  src: url('./fonts/Manrope-Medium.woff2') format('woff2'),
    url('./fonts/Manrope-Medium.woff') format('woff'),
    url('./fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  src: url('./fonts/Manrope-ExtraBold.woff2') format('woff2'),
    url('./fonts/Manrope-ExtraBold.woff') format('woff'),
    url('./fonts/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'jetbrains_mono';
  src: url('./fonts/JetBrainsMono-Regular.woff2') format('woff2'),
    url('./fonts/JetBrainsMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
